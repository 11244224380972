var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "not-supported" },
    [
      _c("ui-frame", [
        _c("h1", [_vm._v(_vm._s(_vm.$t("Error.Header.NotSupported")))]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("Error.Description.NotSupported")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "image-group" }, [
          _vm.desktopOrAndroid
            ? _c("img", { attrs: { src: "/public/images/chrome-logo.png" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.desktop
            ? _c("img", { attrs: { src: "/public/images/firefox-logo.png" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.desktopOrAndroid
            ? _c("img", { attrs: { src: "/public/images/edge-logo.png" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.desktopOrIOS
            ? _c("img", { attrs: { src: "/public/images/safari-logo.png" } })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "url-box" }, [
          _vm._v("\n      " + _vm._s(_vm.url) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "button-group" },
          [
            _c("ui-button", {
              attrs: { label: "App.CopyUrl", additional: "" },
              on: { click: _vm.copy },
            }),
            _vm._v(" "),
            _c("ui-button", {
              attrs: { label: "App.Back" },
              on: { click: _vm.goBack },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }