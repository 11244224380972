<template>
  <div class="not-supported">
    <ui-frame>
      <h1>{{ $t('Error.Header.NotSupported') }}</h1>
      <p>
        {{ $t('Error.Description.NotSupported') }}
      </p>
      <div class="image-group">
        <img v-if="desktopOrAndroid" src="/public/images/chrome-logo.png" />
        <img v-if="desktop" src="/public/images/firefox-logo.png" />
        <img v-if="desktopOrAndroid" src="/public/images/edge-logo.png" />
        <img v-if="desktopOrIOS" src="/public/images/safari-logo.png" />
      </div>
      <p class="url-box">
        {{ url }}
      </p>
      <div class="button-group">
        <ui-button label="App.CopyUrl" additional @click="copy" />
        <ui-button label="App.Back" @click="goBack" />
      </div>
    </ui-frame>
  </div>
</template>

<script>
import { activityLogger } from '@src/scripts/activityLogger'
import { copyToClipboard } from '@src/scripts/helpers'

export default {
  data() {
    return {
      url: location.href
    }
  },

  computed: {
    browserData() {
      return this.$store.state && this.$store.state.browserData
    },

    desktop() {
      const { browserData } = this
      return !browserData.isMobileDevice
    },

    desktopOrAndroid() {
      const { browserData } = this
      return !browserData.isMobileDevice || browserData.isAndroid
    },

    desktopOrIOS() {
      const { browserData } = this
      return !browserData.isMobileDevice || browserData.isIOS
    }
  },

  created() {
    activityLogger.logActivity('BROWSER_NOT_SUPPORTED')
    this.copy()
  },

  methods: {
    goBack() {
      window.history.back()
    },

    copy() {
      copyToClipboard(this.url)
    }
  }
}
</script>

<style scoped>
.not-supported {
  display: flex;
  text-align: center;
}

h1 {
  margin-bottom: 2rem;
  color: var(--header-contrast-color);
}

p {
  color: var(--text-contrast-color);
}

.image-group {
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.image-group img {
  max-width: 7rem;
  margin: 1rem;
}

.url-box {
  border: 1px solid var(--grey);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  word-break: break-all;
  text-align: left;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ui-button {
  margin: 1rem 0.5rem 0;
}
</style>
